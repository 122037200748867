import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, TextField, FormHelperText, FormControl,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { DateRangePicker } from '@material-ui/pickers';
import { dateShortFormat } from 'shared/constants/dateFormats';

const RangeDatePicker = ({
  onChange, value, error, setOpen, open, allowedDates, minDate, maxDate, onBlur, disabled,
}) => {
  const dateInArray = (date, array) => array.some(d => +d === +date);

  const disableDates = date => {
    if (allowedDates.length > 0) {
      return (!dateInArray(date, allowedDates));
    }
    return false;
  };

  const toggleDateRangePicker = () => {
    setOpen(!open);
  };

  return (
    <FormControl error={Boolean(error?.message)} fullWidth>
      <DateRangePicker
        minDate={minDate}
        maxDate={maxDate}
        open={open}
        inputFormat={dateShortFormat}
        value={value || [null, null]}
        onChange={onChange}
        shouldDisableDate={disableDates}
        onClose={() => setOpen(false)}
        renderInput={(startProps, endProps) => (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <TextField
                onClick={toggleDateRangePicker}
                {...startProps}
                helperText=""
                label="Start Date"
                variant="filled"
                error={Boolean(error?.message)}
                fullWidth
                required
                onBlur={onBlur}
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <RemoveIcon />
            </Grid>
            <Grid item xs>
              <TextField
                onClick={toggleDateRangePicker}
                {...endProps}
                helperText=""
                label="End Date"
                variant="filled"
                error={Boolean(error?.message)}
                fullWidth
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Box marginLeft="14px">
                <FormHelperText>{error.message}</FormHelperText>
              </Box>
            </Grid>
          </Grid>
        )}
      />
    </FormControl>
  );
};

RangeDatePicker.defaultProps = {
  setOpen: () => {},
  error: {},
  allowedDates: [],
  minDate: null,
  maxDate: null,
  onBlur: () => {},
  disabled: false,
};

RangeDatePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  allowedDates: PropTypes.arrayOf(PropTypes.object),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RangeDatePicker;
